<template>
  <v-app>
    <AuthQrCode v-if="!onePassword" :connection-id="connectionId"></AuthQrCode>
    <TablePrompter v-if="onePassword" ></TablePrompter>
  </v-app>
</template>

<script>

import * as signalR from "@microsoft/signalr";
import AuthQrCode from '../components/AuthQrCode.vue'
import TablePrompter from '../components/TablePrompter.vue'
import {mapState} from "vuex";
export default {
  components: {
    AuthQrCode,TablePrompter
  },
  computed: {
    ...mapState(['onePassword', 'tablesState'])
  },
  mounted() {
  },
  data() {
    return {
      connectionId: null
    };
  },
  methods: {
    onInfoFromServer(data)
    {
      console.log(data);
      if (data['actionName'] === 'EnableWeb') {
        localStorage.setItem('one_password', data['message']);
        this.$store.commit('set_one_password', data['message'] );
      }
      if (data['actionName'] === 'DisableWeb') {
        localStorage.removeItem('one_password');
        this.$store.commit('set_one_password', null );
      }
      if (data['actionName'] === 'TableState') {
        this.$store.commit('set_tables_state', data['message'] );
      }
    },
    intiHubConnection() {
      const self = this;
      console.log(this.onePassword)
      this.hub = !this.onePassword ? new signalR.HubConnectionBuilder()
          .withUrl('/webinfo/')
          .build() :
          new signalR.HubConnectionBuilder()
              .withUrl(`/webinfo/?one-password=${this.onePassword}`)
              .build()
      ;
      this.$store.hub = this.hub;

      async function start() {
        try {
          await self.hub.start().then(() => {
            console.log("start hub")
          });
          self.hub.invoke('getConnectionId')
              .then(function (connectionId) {
                console.log(connectionId);
                self.connectionId = connectionId;
              });
          self.hub.on('SetInfoClient', self.onInfoFromServer);
          console.log("SignalR Connected.");
        } catch (err) {
          console.log(err);
          self.connectionId = null;
          setTimeout(start, 5000);
        }
      }

      this.hub.onclose(async () => {
        await start();
      });

      start();
    },
  },
  created() {
    let onePass = localStorage.getItem('one_password');
    console.log(onePass)
    if (onePass)
      this.$store.commit('set_one_password', onePass );
    this.intiHubConnection();
  }
};
</script>

<style scoped>
.app-screen {
  padding: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  }
.lobby {
  position: absolute;
  margin-top: 50px;
  right: 20px;
  z-index: 0;
  
}
.ingame {
  position: absolute;
  margin-top: 2px;
  right: 190px;
}
.ingame-reason {
  position: absolute;
  margin-top: 2px;
  right: 170px;
}
</style>
