<template>
  <v-card class="auth-qrcode">
    <div >
      <v-card-item>
        <v-card-title>
          Authentication prompter
        </v-card-title>
        <v-card-subtitle>
          Scan the qr code from the NousLink app
        </v-card-subtitle>
      </v-card-item>

      <QrcodeVue v-if="connectionId" class="auth-code" :value="connectionId" size="300" level="H" render-as="svg" margin="100" background="#000000"
                 foreground="#ffffff"></QrcodeVue>
      <v-card-text v-if="!connectionId">
       <v-icon color="red">mdi-connection</v-icon> No connection server
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import {mapState} from "vuex";

export default {
  props: {
    connectionId:String
  },
  components: {
    QrcodeVue,
  },
  name: "AuthQrCode",
  data() {
    return {
    }
  },
  computed: {
    ...mapState(
        []
    )
  }
}

</script>

<style scoped>
.auth-qrcode {
  padding: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-label {
  margin-top: -290px;
  margin-right: 20px;
  font-family: Manrope, sans-serif;
  font-size: 40px;
}

.auth-code{
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Параметры тени */
}

</style>