<template>
  <v-card
      class="mx-auto"
      width="700"
      prepend-icon="mdi-home"
  >
    <v-card-title>Table prompter</v-card-title>
    <v-divider></v-divider>
    <div v-if="tablesState">
      <div v-for="(table, k) in tablesState.tables" :key="k">
        <v-card height="200">
          <v-card-title>Table id: {{ table.tableId }}</v-card-title>
          <div v-if="table.hint" :style="`background-color: ${color(table)}; color: black;margin: 10px`">
            <div
                style="font-size: 30px; font-weight: bold; display:inline-block; margin-bottom: 10px; margin-right: 10px ">
              {{ hintText(table) }}
            </div>
            <div style="font-size: 30px; display:inline-block;">{{ table.hint.bidAmount }}</div>
            <div style="font-size: 20px; margin-top: 10px;">{{ table.hint.fastButtonName }}</div>
          </div>
          <div v-if="table.notification && table.notification !== ''" style="font-size: 20px; margin-top: 10px;">{{ table.notification }}</div>
        </v-card>
        <v-divider></v-divider>
      </div>
    </div>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {},
  components: {},
  name: "TablePrompter",
  data() {
    return {}
  },
  methods: {
    hintText(table) {
      if (table.hint.actionType === 2) {
        return 'Call';
      }
      if (table.hint.actionType === 4) {
        return 'Raise';
      }
      if (table.hint.actionType === 6) {
        return 'All-In';
      }
      if (table.hint.actionType === 3) {
        return 'Check';
      }
      if (table.hint.actionType === 5) {
        return 'Bet';
      }
      if (table.hint.actionType === 1) {
        return 'Fold';
      }

      return 'NONE';
    },
    color(table) {
      if (table.hint.actionType === 2) {
        return 'yellow';
      }
      if (table.hint.actionType === 4) {
        return 'green';
      }
      if (table.hint.actionType === 6) {
        return 'green';
      }
      if (table.hint.actionType === 3) {
        return 'yellow';
      }
      if (table.hint.actionType === 5) {
        return 'green';
      }
      if (table.hint.actionType === 1) {
        return 'red';
      }

      return 'red';

    }
  },
  mounted() {
    console.log(this.tablesState);
  },
  computed: {
    ...mapState(
        ['tablesState']
    )
  }
}

</script>

<style scoped>
.auth-qrcode {
  padding: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-label {
  margin-top: -290px;
  margin-right: 20px;
  font-family: Manrope, sans-serif;
  font-size: 40px;
}

.auth-code {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Параметры тени */
}

</style>