import Vue from "vue";
import App from "./components/App.vue";
import store from "./store.js";
import vuetify from "./plugins/vuetify";

new Vue({
  vuetify,
  store: store,
  components: {
    "pilot-app": App,
  },
  mounted() {
  },
  sockets: {
    connect() {
    },
  },
  render: (h) => h(App),
}).$mount("#app");



