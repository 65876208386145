import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    onePassword: null,
    tablesState: null
  },
  mutations: {
    set_one_password(state, data) {
      state.onePassword = data;
    },
    set_tables_state(state, data) {
      state.tablesState = data;
    }
  },
  hub: null
});

export default store;
